<template>
	<v-container fluid>
		<v-card max-width="600" class="mx-auto mt-5" rounded="xl">
			<v-card-title>
				<h1 class="display-1">
					{{ $t('auth.accountVerification') }}
				</h1>
			</v-card-title>
			<v-card-subtitle>{{ $t('auth.verifyYourself') }}</v-card-subtitle>
			<v-card-text v-if="authenticated">
				<p>{{ $t('auth.verifyEmailSent', { email: currentUser.email }) }}.</p>
				<small class="form-text text-muted">{{ $t('auth.verifyEmailSentDuration') }} {{ $t('auth.verifyEmailSentSpam') }}</small>
			</v-card-text>

			<v-divider />

			<v-card-actions>
				<v-btn color="primary" @click="callSendVerifyAccountEmail()">
					{{ $t('auth.resendEmail') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('auth.accountVerification'),
		}
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.open()
		if (this.$route.query.token) {
			this.verifyAccount(this.$route.query.token).then(({ success }) => {
				this.close()
				if (success) {
					this.$router.push({ name: 'AuthSetup' })
					return
				}
			})
		} else {
			// Make sure the user is logged in or not by refreshing the token
			this.refreshAccess()
				.then(({ success }) => {
					if (
						success &&
						this.setupInfo.verified.value &&
						(!this.setupInfo.setup.gDriveEmail ||
							!this.setupInfo.studies.verifiedStudies ||
							(this.setupInfo.accountType.student.value && this.setupInfo.studies.allAlumni))
					) {
						this.$router.push({ name: 'AuthSetup' })
					} else if (!success) {
						this.$router.push({ name: 'Landing' })
					}
				})
				.catch((error) => {
					console.error(error)
					this.close()
				})
				.then(() => {
					this.close()
				})
		}
	},
	methods: {
		callSendVerifyAccountEmail() {
			this.sendVerifyAccountEmail()
		},
		...mapActions('auth', ['refreshAccess', 'sendVerifyAccountEmail', 'verifyAccount']),
		...mapActions('splash', ['open', 'close']),
	},
}
</script>
